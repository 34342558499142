import * as React from 'react';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ICookieConfig, ICookieSelection } from 'components/Cookie';
import { closeOverlay } from 'data/overlays/overlayHandler';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import Block from 'ekaubamaja-ui/lib/Components/Block';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';

export interface ICookieSettingsProps {
    config: ICookieConfig;
    saveData: (cookiesToSend: ICookieSelection[]) => void;
    handleAcceptAll: () => void;
}

const CookieSettings = (props: ICookieSettingsProps) => {
    const dispatch = useDispatch();
    const [cookieSelectionArray, setCookieSelectionArray] = useState<ICookieSelection[]>(props.config.cookieSelection);
    const handleAcceptSelected = useCallback(async () => {
        const cookiesToSend = cookieSelectionArray.map((cookieSelection: ICookieSelection) => {
            // set all undefined as false
            if (!cookieSelection.isChecked) {
                cookieSelection.isChecked = false;
            }
            if (cookieSelection.is_required) {
                cookieSelection.isChecked = true;
            }
            return cookieSelection;
        });
        props.saveData(cookiesToSend);
    }, []);

    const setSelected = (selection: ICookieSelection) => {
        const selectedArray = cookieSelectionArray.map((cookieSelection: ICookieSelection) => {
            if (!selection.is_required && cookieSelection.id === selection.id) {
                cookieSelection.isChecked = !cookieSelection.isChecked;
            }
            return cookieSelection;
        });
        setCookieSelectionArray(selectedArray);
    };

    return (
        <React.Fragment>
            <Overlay
                layout="focusview"
                title={props.config.settingsLabel}
                isOpen={true}
                doClose={() => {
                    dispatch(closeOverlay({ name: 'all' }));
                }}
                zIndex={16000004} // cookie message + 1
                size="medium"
            >
                <Block>
                    <p dangerouslySetInnerHTML={{ __html: props.config.introText }} />
                    <LayoutForm>
                        {cookieSelectionArray.map((selection: ICookieSelection, index: number) => {
                            return (
                                <CheckboxWithLabel
                                    key={index}
                                    label={selection.name}
                                    checked={selection.is_required || selection.isChecked}
                                    onChange={() => setSelected(selection)}
                                    disabled={selection.is_required}
                                    description={selection.description}
                                />
                            );
                        })}
                        <Buttons layout="centered">
                            <Button
                                title={props.config.agreeToSelectedLabel}
                                intent="primary"
                                onClick={() => handleAcceptSelected()}
                            />
                            <Button
                                title={props.config.agreeToAllLabel}
                                intent="primary"
                                onClick={() => props.handleAcceptAll()}
                            />
                        </Buttons>
                    </LayoutForm>
                </Block>
            </Overlay>
        </React.Fragment>
    );
};

CookieSettings.displayName = 'CookieSettings';

export default CookieSettings;
