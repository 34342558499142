import * as React from 'react';

import { useEffect, useState } from 'react';
import 'ekaubamaja-ui/lib/Applications/Checkout/Components/Banklinks/styles.scss';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow/index';
import { useTranslation } from 'react-i18next';
import { useMutation, useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput/index';
import { isLoggedIn } from '../../../helpers/customer/isLoggedIn';
import { useSelector } from 'react-redux';
import { empty } from '../../../helpers/empty';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';

interface IProps {
    setPersonalIdSet: (isSet: boolean) => void;
    errors: boolean;
}
const mapStateToProps = (state) => {
    return {
        personalCode: state.entities.personalCode,
    };
};
const PersonalIdForm = (props: IProps) => {
    const { t } = useTranslation();
    const [contactId, setContactId] = useState('');
    const url = isLoggedIn ? 'carts/mine/personal-code' : `guest-carts/${window.quoteIdMask}/personal-code`;
    useRequest(request({ type: 'personalCode', url }));
    const [{}, setRequest] = useMutation(() =>
        request({ method: 'POST', type: 'setPersonalCode', url, data: { personalCode: contactId } }),
    );
    const { personalCode } = useSelector(mapStateToProps);

    useEffect(() => {
        if (!empty(personalCode)) {
            props.setPersonalIdSet(true);
            setContactId(personalCode);
        }
    }, [personalCode]);

    const saveContactId = () => {
        setRequest().then((response) => {
            if (response.body === true || response.body === 'true') {
                props.setPersonalIdSet(contactId.length > 0 && true);
            } else {
                if (response.body.errorMessage) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.errorMessage,
                    });
                }
            }
        });
    };

    return (
        <React.Fragment>
            <FormRow
                label={t('checkout.Contract signer personal ID')}
                required={true}
                error={props.errors && empty(contactId) && t('This is a required field')}
            >
                <ControlInput
                    value={contactId}
                    onBlur={saveContactId}
                    onChange={(e) => {
                        const result = e.target.value.replace(/\D+/g, '');
                        setContactId(result);
                    }}
                />
            </FormRow>
            <span dangerouslySetInnerHTML={{ __html: t('I agree to the insurance Terms and Conditions') }} />
        </React.Fragment>
    );
};
export default PersonalIdForm;
