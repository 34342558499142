import { default as React, useCallback, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import { useMutation } from 'redux-query-react';
import authRequest from 'data/requests/auth/socialAuthRequest';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import OverlayConfirm from 'components/overlay/OverlayConfirm';

interface IProps<T extends string> {
    config: {
        buttonLabel: string;
        appId: string;
        accountIdentifier: string;
        url: string;
        userIdentifier: string;
        overlayTitle: string;
        overlayDescription: string;
        overlayButtonCancel: string;
        overlayButtonConfirm: string;
    };
}

const FacebookAuth = <T extends string>(props: IProps<T>): JSX.Element => {
    const { config } = props;
    const [{}, request] = useMutation((data: Object) => authRequest(data, config.url));
    const [showConfirm, setShowConfirm] = useState(false);

    const responseFacebook = (response) => {
        const formData = new FormData();
        formData.append('account', config.accountIdentifier);
        if (!config.userIdentifier) {
            formData.append('accessToken', response.accessToken); // login requered
            formData.append('userId', response.id); // attach required
        } else {
            formData.append('userId', config.userIdentifier);
        }

        request(formData).then((magentoResponse) => {
            Toaster.addToast({
                intent: magentoResponse.status === 200 ? 'success' : 'danger',
                text: magentoResponse.body.message,
                asHtml: true,
            });

            if (magentoResponse.status === 200) {
                // magento side session isset so reload in order for magento to decide what to do with logged user
                window.location.reload();
            }
        });
    };

    const handleClose = useCallback(async () => {
        setShowConfirm(false);
    }, []);

    const handleDeleteClick = useCallback(async () => {
        setShowConfirm(true);
    }, []);

    return (
        <React.Fragment>
            {!config.userIdentifier ? (
                <FacebookLogin
                    appId={config.appId}
                    autoLoad={false}
                    callback={responseFacebook}
                    cssClass="form-buttons__social facebook"
                    version="8.0"
                    textButton={config.buttonLabel}
                    render={(renderProps) => (
                        <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="form-buttons__social facebook"
                        >
                            {config.buttonLabel}
                        </button>
                    )}
                />
            ) : !showConfirm ? (
                <button onClick={() => handleDeleteClick()} className="form-buttons__social facebook">
                    {config.buttonLabel}
                </button>
            ) : (
                <OverlayConfirm
                    title={props.config.overlayTitle}
                    description={props.config.overlayDescription}
                    buttonCancel={props.config.overlayButtonCancel}
                    buttonOk={props.config.overlayButtonConfirm}
                    onAccept={() => responseFacebook(null)}
                    onClose={handleClose}
                />
            )}
        </React.Fragment>
    );
};

export default FacebookAuth;
