import * as React from 'react';
import 'ekaubamaja-ui/lib/Components/CategorySelect/styles.scss';

export interface ICheckboxWithLabelProps {
    /** Label to display */
    label: string | React.ReactNode;
    /** State of Checkbox */
    checked: boolean;
    /** Disabled status */
    disabled?: boolean;
    /** Change event */
    onChange: (event) => void;
    /** DOM target element name attribute */
    name?: string;
    /** DOM target element id attribute */
    id?: string;
    /** Value */
    value?: string;
}

interface IState {}

/**
 * PlainCheckboxWithLabel extension to display label before Checkbox element.
 */
class PlainCheckboxWithLabel extends React.Component<ICheckboxWithLabelProps, IState> {
    static displayName: string = 'PlainCheckboxWithLabel';

    private nodeCheckbox;

    render() {
        return (
            <React.Fragment>
                <input
                    type="checkbox"
                    checked={this.props.checked}
                    disabled={this.props.disabled}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    name={this.props.name}
                    ref={(node) => (this.nodeCheckbox = node)}
                    id={this.props.id}
                />
                <label
                    htmlFor={this.props.id}
                    onClick={() => {
                        if (this.nodeCheckbox) {
                            this.nodeCheckbox.click();
                        }
                    }}
                >
                    {this.props.label}
                </label>
            </React.Fragment>
        );
    }
}

export default PlainCheckboxWithLabel;
