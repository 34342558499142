import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useRequest } from 'redux-query-react';
import listRequest from 'data/requests/product/listRequest';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import {
    ActiveFilter,
    Categories,
    CategoryListItem,
    Filter,
    IListResponse,
    Pagination,
    SortOrder,
} from 'components/Catalog/Interfaces/List/IListResponse';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import Filters from 'components/Catalog/Product/ListView/Filters';
import CategoryFilter from 'ekaubamaja-ui/lib/Components/CategoryFilter';
import Toolbar from 'components/Catalog/Product/ListView/Toolbar';
import Products from 'components/Catalog/Product/ListView/Products';
import { parse, stringify } from 'query-string';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';
import Paginator from 'components/Catalog/Product/ListView/Paginator';
import SelectFilters from 'components/Catalog/Product/ListView/SelectFilters';
import CategoryTree from 'ekaubamaja-ui/lib/Components/CategoryTree';
import ReactDOMServer from 'react-dom/server';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { clone, includes } from 'lodash';
import { empty } from '../../../helpers/empty';
import CategorySelectFilters from 'components/Catalog/Product/ListView/CategorySelectFilters';
import tecDocListRequest from 'data/requests/product/tecDocListRequest';
import OrderSparePart from 'components/Form/Catalog/OrderSparePart';
import { ITranslations } from 'components/Catalog/Product/ListViewWrapper';
import LoadingContent from 'ekaubamaja-ui/lib/Components/LoadingContent';
import quoteIdRequest from 'data/requests/product/quoteIdRequest';

interface IProps {
    dom: string;
    isTecDocView: boolean;
    labels?: ITranslations;
    actionUrl?: string;
}

const mapStateToProps = (state): IListResponse => {
    return {
        filters: state.entities.productList?.filters as Filter[],
        products: state.entities.productList?.products,
        categories: state.entities.productList?.categories as Categories,
        pagination: state.entities.productList?.pagination as Pagination,
        orderField: state.entities.productList?.orderField,
        orderDir: state.entities.productList?.orderDir,
        orders: state.entities.productList?.orders as SortOrder[],
        assets: state.entities.productList?.assets,
        activeFilters: state.entities.productList?.activeFilters as ActiveFilter[],
        clearUrl: state.entities.productList?.clearUrl,
    };
};
const ListView = (props: IProps) => {
    const { dom, isTecDocView, labels, actionUrl } = props;
    const location = useLocation();

    const getSlug = (newLocation) => {
        if (newLocation.pathname.startsWith('/catalogsearch')) {
            return null;
        } else if (newLocation.pathname.startsWith('/tecDocProductList')) {
            // get carId and categoryId from url
            const url = newLocation.pathname.split(/[/]/);

            return `tecDocProductList/filter/index/carId/${url[5]}/categoryId/${url[7]}`;
        }

        return newLocation.pathname.replace('/', '');
    };
    let slug = getSlug(location);
    const initialQuery = { ...parse(location.search), slug };
    const [queryData, setQueryData] = useState(initialQuery);
    const [useDom, setUseDom] = useState(true);
    const [activeCategory, setActiveCategory] = useState<CategoryListItem | undefined>();
    const currentQuery = useRef(initialQuery);

    const queryParamsToLeave = ['slug', 'q'];
    const queryParams = {};

    queryParamsToLeave.map((param: string) => {
        if (queryData[param]) {
            queryParams[param] = queryData[param];
        }
    });
    const queryParamsForOriginQuery = clone(queryParams);
    queryParams['originQuery'] = true;

    const [{}, tecDocOriginQuery] = useMutation(() => tecDocListRequest(slug, queryParams, 'tecDocProductListOrigin'));
    const [{}, listOriginQuery] = useMutation(() => listRequest(queryParams, 'productListOrigin'));

    const [{ isFinished }] = isTecDocView
        ? useRequest(tecDocListRequest(slug, queryData))
        : useRequest(listRequest(queryData));

    useEffect(() => {
        if (!isFinished && !isEqual(queryParamsForOriginQuery, queryData)) {
            isTecDocView ? tecDocOriginQuery() : listOriginQuery();
        }
    }, [isFinished]);

    let {
        filters,
        products,
        pagination,
        orders,
        assets,
        activeFilters,
        categories,
        orderDir,
        orderField,
        clearUrl,
    } = useSelector(mapStateToProps);
    const history = useHistory();

    /** Overlays **/
    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);
    /** CurrentPage **/
    const query = parse(location.search);
    const initialPage = parseInt((query.p as string) || pagination?.page.toString() || '1');
    const currentPage = useRef(initialPage);
    const [pageNumber, setPageNumber] = useState(initialPage);
    const [pageBreadCrumbs, setPageBreadCrumbs] = useState<CategoryListItem[]>([]);

    const parser = new DOMParser();
    const doc = parser.parseFromString(dom, 'text/html');
    const toolbarDom = doc.querySelectorAll('[data-component="toolbar"]')[0]?.innerHTML || '';
    const filtersDom = doc.querySelectorAll('[data-component="listFilters"]')[0]?.innerHTML || '';
    const productsDom = doc.querySelectorAll('[data-component="productsList"]')[0]?.innerHTML || '';
    const isSearchQuery = !empty(query.q);
    const categoryFilter = filters ? filters.find((filter: Filter) => filter.id === 'cat') : undefined;

    let pageTitle = '';
    const handleCategoriesActive = (categories: CategoryListItem[]) => {
        categories.forEach((item: CategoryListItem) => {
            if (item.active) {
                pageBreadCrumbs.push(item);
                pageTitle = item.name;
                setActiveCategory(item);
                if (item.children && item.children.length) {
                    handleCategoriesActive(item.children);
                }
            }
        });

        if (includes(openOverlays, 'mobilefilter')) {
            dispatch(closeOverlay({ name: 'mobilefilter' }));
        }
    };

    useEffect(() => {
        const unlisten = history.listen((newLocation) => {
            window.scrollTo(0, 0);

            slug = getSlug(newLocation);
            const searchQuery = parse(newLocation.search);
            if (searchQuery['p'] && currentQuery.current && currentQuery.current['p'] === searchQuery['p']) {
                delete searchQuery['p'];
                currentQuery.current = { ...searchQuery, slug };
                history.push(location.pathname + '?' + stringify(searchQuery));
            }
            currentQuery.current = { ...searchQuery, slug };
            setQueryData({ ...searchQuery, slug });
            const newPageNumber = parseInt((searchQuery['p'] ?? '1') as string);
            if (currentPage.current !== newPageNumber) {
                setPageNumber(newPageNumber);
                currentPage.current = newPageNumber;
            }
        });
        return function cleanup() {
            unlisten();
        };
    }, [history]);

    useEffect(() => {
        if (isFinished && useDom) {
            setUseDom(false);
        }
    }, [isFinished]);

    useEffect(() => {
        if (isFinished && categories && categories.items) {
            setPageBreadCrumbs([]);
            handleCategoriesActive(categories.items);
            document.title = pageTitle;
            window.dispatchEvent(new CustomEvent('page-changed', { detail: { pageTitle, pageBreadCrumbs } }));
        }
    }, [isFinished, categories]);

    return (
        <React.Fragment>
            <div className={'layout-browse__sidebar'}>
                {isSearchQuery && categoryFilter && (
                    <CategorySelectFilters assets={assets} categoryFilter={categoryFilter} />
                )}
                <CategoryTree
                    dom={
                        useDom
                            ? filtersDom
                            : ReactDOMServer.renderToString(
                                  <Filters
                                      filters={filters}
                                      assets={assets}
                                      activeFilters={activeFilters}
                                      categories={categories}
                                      isSearchQuery={isSearchQuery}
                                  />,
                              )
                    }
                    openMobileFilter={(e) => {
                        e.preventDefault();
                        dispatch(closeOverlay({ name: 'all' }));
                        dispatch(openOverlay({ name: 'mobilefilter' }));
                    }}
                    closeMobileFilter={(e) => {
                        e.preventDefault();
                        dispatch(closeOverlay({ name: 'all' }));
                    }}
                    mobileFilterOpen={includes(openOverlays, 'mobilefilter')}
                    link={NavLink}
                    query={location.search}
                    location={location}
                    history={history}
                    keepOriginalHref={true}
                />
            </div>
            {!isFinished && (
                <div className={'layout-browse__main'}>
                    <LoadingContent layout="inline" size="large" />
                </div>
            )}
            {isFinished && (
                <div className={'layout-browse__main'}>
                    {products && filters.length > 0 && (
                        <SelectFilters
                            clearUrl={clearUrl}
                            filters={filters}
                            assets={assets}
                            activeFilters={activeFilters}
                        />
                    )}
                    {useDom ||
                        (products && products.length > 0 && (
                            <React.Fragment>
                                <div className="product-toolbar">
                                    <CategoryFilter
                                        dom={
                                            useDom
                                                ? toolbarDom
                                                : ReactDOMServer.renderToString(
                                                      <Toolbar
                                                          orderField={orderField}
                                                          orders={orders}
                                                          assets={assets}
                                                          orderDir={orderDir}
                                                      />,
                                                  )
                                        }
                                        link={NavLink}
                                        query={location.search}
                                        navigate={(query) => {
                                            history.push(query);
                                        }}
                                    />
                                </div>
                                {!useDom ? (
                                    <Products products={products} inCartBlock={assets.inCartBlock} assets={assets} />
                                ) : (
                                    <RenderHTML html={productsDom} nowrapper={true} />
                                )}
                                {pagination && (
                                    <Paginator
                                        pagination={pagination}
                                        assets={assets}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                    />
                                )}
                            </React.Fragment>
                        )) ||
                        (products && products.length < 1 && !actionUrl && (
                            <div className="generic-message">
                                <div className="generic-message__limiter">
                                    <div className="generic-message__title">{assets.noProductsTitleLabel}</div>
                                    <div className="generic-message__description">{assets.noProductsMessageLabel}</div>
                                </div>
                            </div>
                        ))}
                    {!useDom && products && products.length < 1 && activeCategory && labels && actionUrl && (
                        <OrderSparePart activeCategory={activeCategory} labels={labels} actionUrl={actionUrl} />
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default ListView;
