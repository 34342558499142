import * as React from 'react';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay/index';
import { useTranslation } from 'react-i18next';
import useOverlays from '../../../../components/overlay/Overlay';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import Block from 'ekaubamaja-ui/lib/Components/Block';

interface IProps {
    input: string;
    index: string;
    asHtml: boolean;
}

const AgreementsOverlay = (props: IProps) => {
    const { t } = useTranslation();
    const { overlays, closeOverlay } = useOverlays();

    const closeButton = (
        <Button
            title={t('checkout.Close')}
            intent="primary"
            layout="link"
            onClick={(e) => {
                e.preventDefault();
                closeOverlay(props.index);
            }}
        />
    );

    return (
        <React.Fragment>
            <Overlay
                isOpen={overlays.indexOf(props.index) !== -1}
                layout="focusview"
                size="small"
                doClose={(e) => {
                    e.preventDefault();
                    closeOverlay(props.index);
                }}
            >
                <Block>
                    {closeButton}
                    {props.asHtml && <div dangerouslySetInnerHTML={{ __html: props.input }} />}
                    {!props.asHtml && <p>{props.input}</p>}
                    {closeButton}
                </Block>
            </Overlay>
        </React.Fragment>
    );
};
export default AgreementsOverlay;
