import React from 'react';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay/index';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../../../../helpers/rootReducer';
import { includes } from 'lodash';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import { ITranslations, IUrls } from 'components/SearchBlock/SearchBlock';
import { closeOverlay } from 'data/overlays/overlayHandler';
import { useRequest } from 'redux-query-react';
import vehicleIndexRequest from 'data/requests/customer/VehicleIndexRequest';
import LoadingContent from 'ekaubamaja-ui/lib/Components/LoadingContent/index';

interface IProps {
    labels: ITranslations;
    urls: IUrls;
    overlaySelector: string;
    setSelectedVehicle: (vehicle: IVehicle) => void;
    setLicence: (licence: string) => void;
}

interface IMyVehicle {
    plateNumber: string;
    manufacturerName: string;
    manufacturerId: string;
    modelName: string;
    modelId: string;
    engineName: string;
    engineId: string;
}

interface myVehicles {
    myVehicles: IMyVehicle[];
}

const mapStateToProps = (state): myVehicles => {
    return {
        myVehicles: state.entities.vehicleIndex,
    };
};

const SelectCarOverlay = (props: IProps) => {
    const { labels, urls, overlaySelector } = props;
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const [{ isFinished }] = useRequest(vehicleIndexRequest());
    const { myVehicles } = useSelector(mapStateToProps);

    const selectVehicle = (vehicle: IMyVehicle) => {
        props.setSelectedVehicle({
            manufacturer: {
                id: vehicle.manufacturerId,
                label: vehicle.manufacturerName,
            },
            model: {
                id: vehicle.modelId,
                label: vehicle.modelName,
            },
            licence: vehicle.plateNumber,
            type: {
                id: vehicle.engineId,
                label: vehicle.engineName,
            },
        });
        props.setLicence(vehicle.plateNumber);
        dispatch(closeOverlay({ name: 'all' }));
    };

    const close = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };

    return (
        <React.Fragment>
            {!isFinished && (
                <div className={'layout-browse__main'}>
                    <LoadingContent layout="inline" size="large" />
                </div>
            )}
            <Overlay
                isOpen={includes(openOverlays, overlaySelector)}
                doClose={() => close()}
                layout="focusview"
                buttonIcons={[
                    {
                        icon: 'close',
                        intent: 'link',
                        onClick: (e) => {
                            e.preventDefault();
                            close();
                        },
                    },
                ]}
                size="wide"
                title={labels.selectOwnCarModal}
            >
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>{labels.registrationNumber}</th>
                                <th>{labels.manufacturer}</th>
                                <th>{labels.model}</th>
                                <th>{labels.motor}</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myVehicles &&
                                myVehicles.map((vehicle: IMyVehicle, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{vehicle.plateNumber}</td>
                                            <td>{vehicle.manufacturerName}</td>
                                            <td>{vehicle.modelName}</td>
                                            <td>{vehicle.engineName}</td>
                                            <td>
                                                <button
                                                    onClick={() => selectVehicle(vehicle)}
                                                    className="Button-button-0-2-1 button layout-default intent-primary"
                                                >
                                                    <span className="button-inner">
                                                        <span className="text">{labels.selectButtonLabel}</span>
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            {isFinished && myVehicles && !myVehicles.length && (
                                <tr>
                                    <td colSpan={5}>
                                        <a href={urls.accountUrl}>{labels.noVehiclesLabel}</a>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Overlay>
        </React.Fragment>
    );
};

export default SelectCarOverlay;
