import * as React from 'react';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';
import { ResponseStatusEnum } from '../../../../enums/ResponseStatus';
import { IGroup, IPayseraConfig, IPayseraPaymentItem } from '../../../../interfaces/payment/Paysera/IPayseraConfig';
import { empty } from '../../../../helpers/empty';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import { useTranslation } from 'react-i18next';
import { PaymentMethodEnum } from '../../../../components/checkout/Payment/Methods/MethodHandler';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
}
let requestDone = false;
let paymentRequestDone = false;
let config: Partial<IPayseraConfig> = {};

const Paysera = (props: IProps) => {
    const [payseraPaymentMethod, setPayseraPaymentMethod] = useState<string>();
    const redirect = useRef({ to: `checkout` });
    const [stateChange, setStateChange] = useState(false);
    const { t } = useTranslation();

    const [{}, requestHelper] = useMutation((requestPayload) => request(requestPayload));

    if (!requestDone) {
        requestDone = true;
        requestHelper({ type: PaymentMethodEnum.paysera, url: 'checkout/paysera' }).then((response) => {
            const responseSuccessful = response && response.status === ResponseStatusEnum.ok;
            if (responseSuccessful) {
                config = response?.body[0];
                setStateChange(!stateChange);
            }
        });
    }

    const methods: any[] = [];
    if (!empty(config)) {
        const simpleElement = (method: IPayseraPaymentItem) => (
            <SimplePayment
                method={method.method}
                key={`paysera-${method.method}`}
                onClick={() => {
                    setPayseraPaymentMethod(method.method);
                    props.paymentMethodProps.onClick({ redirect: undefined });
                }}
                logoUrl={method.logo}
                label={''}
                style={{ margin: '2px' }}
                paymentMethodProps={props.paymentMethodProps}
            />
        );

        config.groups?.forEach((group: IGroup) => {
            group.paymentMethods.forEach((method: IPayseraPaymentItem) => {
                methods.push(simpleElement(method));
            });
        });
    }
    useEffect(() => {
        if (props.paymentMethodProps.paymentMethodExtraData) {
            props.paymentMethodProps.paymentMethodExtraData.current = {
                additionalData: {
                    paysera_payment_method: payseraPaymentMethod,
                },
            };
        }
    }, [payseraPaymentMethod]);

    const effect = (event) => {
        if (event.detail?.setRedirectUrl && event.detail.methodCode === PaymentMethodEnum.paysera) {
            if (!paymentRequestDone) {
                paymentRequestDone = true;
                requestHelper({
                    type: 'payseraPayment',
                    url: `paysera`,
                    notApi: true,
                }).then((response: any) => {
                    let responseSuccessful = response && response.status === ResponseStatusEnum.ok;
                    let body = { url: '' };
                    redirect.current = { to: responseSuccessful ? response.url : `checkout` };
                    try {
                        body = JSON.parse(response.text);
                    } catch (e) {
                        responseSuccessful = false;
                    }
                    if (responseSuccessful && body.url) {
                        event.detail.setRedirectUrl({ to: body.url, absolute: true });
                    } else {
                        Toaster.addToast({
                            intent: 'danger',
                            text: t('paymentFailed'),
                        });
                    }
                });
            }
        }
    };

    useEffect(() => {
        window.addEventListener('payment-response-receive', effect);
        return function cleanup() {
            window.removeEventListener('payment-response-receive', effect);
        };
    });
    return <React.Fragment>{methods}</React.Fragment>;
};

export default Paysera;
