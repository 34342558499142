import * as React from 'react';

import CartButton from 'components/CartButton';
import LongPage from 'components/LongPage';
import Detail from 'components/Catalog/Product/Detail';
import CheckoutCart from 'components/Cart/CheckoutCart';
import AuthorizationHandler from 'components/Authorization/AuthorizationHandler';
import ListViewWrapper from 'components/Catalog/Product/ListViewWrapper';
import Newsletter from 'components/Newsletter';
import Messages from 'components/Messages/Messages';
import CustomerDelete from 'components/AccountDelete/CustomerDelete';
import AddressDelete from 'components/AccountDelete/AddressDelete';
import WebformSubmit from 'components/WebformSubmit';
import GeneralForm from 'components/GeneralForm/GeneralForm';
import RegionSelect from 'components/RegionSelect';
import Search from 'components/Catalog/Search/Search';
import { Title } from 'components/Page/Title';
import { Breadcrumbs } from 'components/Page/Breadcrumbs';
import Carousel from 'ekaubamaja-ui/lib/Components/RenderCarousel/index';
import ConsentHandler from 'components/Gdpr/ConsentHandler';
import MainMenu from 'components/ui/MainMenu';
import RenderLink from 'ekaubamaja-ui/lib/Components/RenderLink/index';
import Cookie from 'components/Cookie';
import GoogleAuth from 'components/GoogleLogin';
import FacebookAuth from 'components/FacebookLogin';
import CrossSell from 'components/CrossSell';
import SearchBlock from 'components/SearchBlock/SearchBlock';
import CategoryList from 'components/CategoryList/CategoryList';
import { ProductsGrid } from 'components/Catalog/Product/ListView/ProductsGrid';
import RegisterFormHandler from 'components/RegisterFormHandler';
import VehicleDelete from 'components/AccountDelete/VehicleDelete';
import ProductTecDocAttributes from 'components/Catalog/Product/Detail/ProductTecDocAttributes';
import ProductDetailBreadcrumbs from 'components/Catalog/Product/ProductDetailBreadcrumbs';
import Filter from 'components/Filter';
import ComponentMapperRecaptcha from 'components/GoogleRecaptcha/ComponentMapperRecaptcha';
import CheckoutProvider from 'components/Checkout/CheckoutProvider';

const componentMapper = (
    maybeComponent: string,
    maybeContent: string,
    maybeConfig,
    ignoreTabs: string[] = [],
): React.Component => {
    return {
        menu: <MainMenu dom={maybeContent} config={maybeConfig} />,
        dropdown: <RenderLink dom={maybeContent} config={maybeConfig} />,
        cart: <CartButton dom={maybeContent} config={maybeConfig} />,
        product: <Detail dom={maybeContent} config={maybeConfig} />,
        checkoutCart: <CheckoutCart config={maybeConfig} />,
        authorization: <AuthorizationHandler config={maybeConfig} />,
        productList: <ListViewWrapper dom={maybeContent} config={maybeConfig} />,
        search: <Search dom={maybeContent} config={maybeConfig} output={'field'} />,
        searchButton: <Search dom={maybeContent} config={maybeConfig} output="button" />,
        searchDesktop: <Search dom={maybeContent} config={maybeConfig} output="desktop" />,
        newsletter: <Newsletter config={maybeConfig} />,
        messages: <Messages />,
        accountDelete: <CustomerDelete config={maybeConfig} />,
        addressDelete: <AddressDelete config={maybeConfig} />,
        webformSubmit: <WebformSubmit config={maybeConfig} />,
        form: <GeneralForm config={maybeConfig} />,
        longpage: <LongPage dom={maybeContent} config={maybeConfig} />,
        regionSelect: <RegionSelect config={maybeConfig} />,
        googleLogin: <GoogleAuth config={maybeConfig} />,
        facebookLogin: <FacebookAuth config={maybeConfig} />,
        crossSell: <CrossSell config={maybeConfig} />,
        title: <Title config={maybeConfig} />,
        breadcrumbs: <Breadcrumbs config={maybeConfig} />,
        searchParts: <SearchBlock config={maybeConfig} />,
        categoryList: <CategoryList config={maybeConfig} />,
        productsGrid: <ProductsGrid config={maybeConfig} />,
        registerFormHandler: <RegisterFormHandler />,
        recaptcha: <ComponentMapperRecaptcha />,
        gdprConsentHandler: <ConsentHandler />,
        cookieSelection: <Cookie config={maybeConfig} />,
        vehicleDelete: <VehicleDelete config={maybeConfig} />,
        productDetailData: <ProductTecDocAttributes dom={maybeContent} config={maybeConfig} />,
        productDetailBreadcrumbs: <ProductDetailBreadcrumbs config={maybeConfig} dom={maybeContent} />,
        filter: <Filter dom={maybeContent} config={maybeConfig} />,
        carousel: <Carousel dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        checkout: <CheckoutProvider />,
    }[(ignoreTabs.includes(maybeComponent) ? '' : maybeComponent) || ''];
};

export default componentMapper;
