import * as React from 'react';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { empty } from '../../../../helpers/empty';
import { useRequest } from 'redux-query-react';
import tecDocProductAttributesRequest from 'data/requests/product/tecDocProductAttributesRequest';
import { ITecDocAttribute } from 'components/Catalog/Interfaces/List/IListResponse';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';

interface IProps {
    dom: string;
    config: {
        tecDocArticleId?: string;
    };
}
const mapStateToProps = (state) => {
    return state.selectedVehicleReducer.selectedVehicle as IVehicle;
};

const mapStateToPropsAttributes = (state) => {
    return {
        attributes: state.entities.tecDocProductAttributes as ITecDocAttribute[],
    };
};

const ProductTecDocAttributes = (props: IProps) => {
    const { tecDocArticleId } = props.config;
    const [selectedVehicle] = useState<IVehicle | undefined>(useSelector(mapStateToProps));

    if (empty(selectedVehicle) || empty(tecDocArticleId) || !selectedVehicle?.type?.id) {
        return (
            <React.Fragment>
                <RenderHTML nowrapper={true} html={props.dom} />
            </React.Fragment>
        );
    }

    const [{ isFinished }] = useRequest(tecDocProductAttributesRequest(tecDocArticleId, selectedVehicle.type.id));
    const { attributes } = useSelector(mapStateToPropsAttributes);

    return (
        <React.Fragment>
            <RenderHTML nowrapper={true} html={props.dom} />
            {isFinished &&
                attributes &&
                attributes.length &&
                attributes.map((attribute: ITecDocAttribute, key: number) => {
                    return (
                        <div key={key} className="tech-specs">
                            <div className="tech-specs__title">{attribute.attributeName}</div>
                            <div className="tech-specs__description">
                                <p>{attribute.attributeValue}</p>
                            </div>
                        </div>
                    );
                })}
        </React.Fragment>
    );
};

export default ProductTecDocAttributes;
