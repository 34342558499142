import React, { useCallback, useState } from 'react';
import { ITranslations, IUrls } from 'components/SearchBlock/SearchBlock';
import LicencePlateInput from 'components/SearchBlock/CarSearch/LicencePlate/LicencePlateInput';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';
import { useMutation } from 'redux-query-react';
import tireLicenceRequest from 'data/tireSearch/TireLicenceRequest';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { useDispatch } from 'react-redux';
import TireOverlay, { TireResponse } from 'components/SearchBlock/TireSearch/LicencePlate/Overlay/TireOverlay';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import SelectCar from 'components/SearchBlock/CarSearch/SelectCar/SelectCar';

interface IProps {
    urls: IUrls;
    labels: ITranslations;
    selectedVehicle?: IVehicle;
    setSelectedVehicle: (vehicle?: IVehicle) => void;
}

const TireLicenceSearch = (props: IProps) => {
    const { urls, labels, selectedVehicle, setSelectedVehicle } = props;
    const [licence, setLicence] = useState(selectedVehicle?.licence ? selectedVehicle.licence : '');
    const [{ isPending }, queryLicence] = useMutation((licence: string) => tireLicenceRequest(licence));
    const [tireResponse, setTireResponse] = useState<TireResponse>();
    const [modalOpen, setModalOpen] = useState(false);
    const overlaySelector = 'select-car';

    const dispatch = useDispatch();

    const hideResults = () => {
        dispatch(closeOverlay({ name: 'all' }));
        setModalOpen(false);
    };

    const showResults = () => {
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: overlaySelector }));
        setModalOpen(true);
    };

    const getTires = useCallback(async (licence) => {
        const response = await queryLicence(licence);
        if (response.status === 200) {
            setTireResponse(response.body);
            setSelectedVehicle({ ...selectedVehicle, licence });

            if (!response.body?.tires) {
                Toaster.addToast({
                    intent: 'danger',
                    text: `0 ${labels.tireSizesFound}`,
                    asHtml: true,
                });
            } else {
                showResults();
            }
        } else {
            Toaster.addToast({
                intent: 'danger',
                text: response.body.message ? response.body.message : labels.genericErrorMessage,
                asHtml: true,
            });
        }
    }, []);

    const loadTires = async () => {
        await getTires(licence);
    };

    return (
        <div className="box-search__group license-search">
            <div className="box-search__label">
                <React.Fragment>
                    {labels.insertRegNrOr}&nbsp;
                    <SelectCar
                        urls={urls}
                        setLicence={setLicence}
                        setSelectedVehicle={setSelectedVehicle}
                        labels={labels}
                    />
                </React.Fragment>
            </div>
            <div className="box-search__fields">
                <div className="column license">
                    <LicencePlateInput
                        licence={licence}
                        placeholder={labels.licencePlatePlaceholder}
                        iconName={'license_est'}
                        label={labels.placeholderEst}
                        setLicence={setLicence}
                        onEnterEvent={loadTires}
                    />
                    {tireResponse && tireResponse.tires && (
                        <div className="box-search__license__results">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    showResults();
                                }}
                            >
                                <span className="label">{`${tireResponse.tires.length} ${labels.tireSizesFound}`}</span>
                                <Icon kind={modalOpen ? 'arrow1-up' : 'arrow1-down'} width={10} height={10} />
                            </a>
                        </div>
                    )}
                </div>
            </div>
            <div className="column action">
                <Button
                    disabled={!licence}
                    title={labels.find}
                    intent="primary"
                    loading={isPending}
                    onClick={loadTires}
                />
            </div>
            {tireResponse && (
                <TireOverlay
                    labels={labels}
                    hideResults={hideResults}
                    tireResponse={tireResponse}
                    overlaySelector={overlaySelector}
                />
            )}
        </div>
    );
};

export default TireLicenceSearch;
