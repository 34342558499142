import React from 'react';
import classNames from 'classnames';
import Img from 'components/Image/Img';
import Icon from 'ekaubamaja-ui/lib/Components/Icon/index';
import { SearchTabType } from 'components/SearchBlock/SearchBlock';

interface IProps {
    tabType: SearchTabType;
    currentTab?: SearchTabType;
    setCurrentTab: (tab?: SearchTabType) => void;
    label: string;
    iconName: string;
}

const SearchBlockTypeHeader = (props: IProps) => {
    const { currentTab, setCurrentTab, tabType, label, iconName } = props;

    return (
        <div
            className={classNames('box-search__group-title', { active: currentTab === tabType })}
            onClick={(e) => {
                e.preventDefault();
                if (currentTab === tabType) {
                    setCurrentTab(undefined);
                } else {
                    setCurrentTab(tabType);
                }
            }}
        >
            <div className="icon">
                <Img iconName={iconName} alt={label} />
            </div>
            <div className="label">{label}</div>
            <Icon
                wrapperClassName="arrow"
                width={16}
                height={16}
                kind={currentTab === tabType ? 'arrow1-up' : 'arrow1-down'}
            />
        </div>
    );
};

export default SearchBlockTypeHeader;
