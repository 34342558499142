import * as React from 'react';
import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import newsletterRequest from 'data/requests/newsletter/newsletterRequest';
import PlainCheckboxWithLabel from 'components/Form/Input/PlainCheckboxWithLabel';
import ControlInputWithoutClass from 'components/Form/Input/ControlInputWithoutClass';
import FormListItem from 'components/Form/List/FormListItem';
import FormList from 'ekaubamaja-ui/lib/Applications/ePharma2/Components/FormList/index';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML/index';

interface ITranslations {
    joinNewsletter: string;
    emailAddress: string;
    agreeToCompanyTerms: string;
    agreeToMarketingTerms: string;
    subscribe: string;
    requiredField: string;
    invalidEmail: string;
}

export interface INewsletterProps {
    config: {
        labels: ITranslations;
    };
}

const Newsletter: React.FunctionComponent<INewsletterProps> = (props) => {
    const { labels } = props.config;
    const [
        {},
        subscribeRequest,
    ] = useMutation((email: string, agreeToCompanyTerms: boolean, agreeToMarketingTerms: boolean) =>
        newsletterRequest(email, agreeToCompanyTerms, agreeToMarketingTerms),
    );

    const FormDataValidationSchema = Yup.object().shape({
        email: Yup.string().email(labels.invalidEmail).nullable().required(labels.requiredField),
        agreeToCompanyTerms: Yup.boolean().nullable().required(labels.requiredField),
        agreeToMarketingTerms: Yup.boolean().nullable().required(labels.requiredField),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            agreeToCompanyTerms: false,
            agreeToMarketingTerms: false,
        },
        validationSchema: FormDataValidationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: () => {
            void handleSubscribe(formik.values);
        },
    });

    const handleSubscribe = useCallback(async (values) => {
        const response = await subscribeRequest(values.email, values.agreeToCompanyTerms, values.agreeToMarketingTerms);
        Toaster.addToast({
            intent: response.status === 200 ? 'success' : 'danger',
            text: response.body.message,
            asHtml: true,
        });

        if (response.status === 200) {
            formik.resetForm();
        }
    }, []);

    return (
        <div className="frame-automaailm__body blue">
            <div className="frame-automaailm__body__limiter">
                <div className="box-newsletter">
                    <h2>{labels.joinNewsletter}</h2>
                    <form method="post" onSubmit={formik.handleSubmit}>
                        <FormList>
                            <FormListItem label={labels.emailAddress} required={true} error={formik.errors.email}>
                                <ControlInputWithoutClass
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                />
                            </FormListItem>
                            <FormListItem error={formik.errors.agreeToCompanyTerms}>
                                <PlainCheckboxWithLabel
                                    label={<RenderHTML nowrapper={true} html={labels.agreeToCompanyTerms} />}
                                    checked={formik.values.agreeToCompanyTerms}
                                    onChange={() => {
                                        formik.setFieldValue('agreeToCompanyTerms', !formik.values.agreeToCompanyTerms);
                                    }}
                                />
                                {formik.values.agreeToCompanyTerms && (
                                    <input type="hidden" name="agreeToCompanyTerms" value="1" />
                                )}
                            </FormListItem>
                            <FormListItem error={formik.errors.agreeToMarketingTerms}>
                                <PlainCheckboxWithLabel
                                    label={<RenderHTML nowrapper={true} html={labels.agreeToMarketingTerms} />}
                                    checked={formik.values.agreeToMarketingTerms}
                                    onChange={() => {
                                        formik.setFieldValue(
                                            'agreeToMarketingTerms',
                                            !formik.values.agreeToMarketingTerms,
                                        );
                                    }}
                                />
                                {formik.values.agreeToMarketingTerms && (
                                    <input type="hidden" name="agreeToMarketingTerms" value="1" />
                                )}
                            </FormListItem>
                        </FormList>
                        <ul className="form-buttons">
                            <li>
                                <button
                                    type="submit"
                                    className="form-buttons__button intent-primary"
                                    disabled={
                                        !formik.values.email ||
                                        !formik.values.agreeToMarketingTerms ||
                                        !formik.values.agreeToCompanyTerms
                                    }
                                >
                                    {labels.subscribe}
                                </button>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    );
};

Newsletter.displayName = 'Newsletter';

export default Newsletter;
