import React from 'react';
import ControlSelect from 'ekaubamaja-ui/lib/Components/ControlSelect/index';

interface IProps {
    options: Array<string>;
    setAttribute: (attribute?: string) => void;
    attribute?: string;
    placeholder: string;
}

const TireDataSelect = (props: IProps) => {
    const { options, placeholder, attribute, setAttribute } = props;

    return (
        <div className="column">
            <div className="control-holder">
                <ControlSelect
                    value={attribute || ''}
                    onChange={(e) => {
                        setAttribute(e.target.value);
                    }}
                    placeholder={placeholder}
                >
                    <option value="" />
                    {options &&
                        options.map((option, key) => (
                            <option key={key} value={option['id']}>
                                {option['value']}
                            </option>
                        ))}
                    ;
                </ControlSelect>
            </div>
        </div>
    );
};

export default TireDataSelect;
