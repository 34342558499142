import * as React from 'react';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { closeOverlay } from 'data/overlays/overlayHandler';
import { useDispatch } from 'react-redux';
import { includes } from 'lodash';
import Block from 'ekaubamaja-ui/lib/Components/Block';
import { IAvailabilityResponse } from 'components/Catalog/Product/Interfaces/IAvailability';

interface IProps {
    openOverlays: string[];
    item: IAvailabilityResponse;
    emailLabel: string;
    phoneLabel: string;
    openingHoursLabel: string;
    addressLabel: string;
}

const StoreInfoOverlay = (props: IProps) => {
    const { openOverlays, item, emailLabel, phoneLabel, openingHoursLabel, addressLabel } = props;
    const dispatch = useDispatch();

    return (
        <Overlay
            layout="focusview"
            canOutsideClickClose={true}
            title={item.name}
            isOpen={includes(openOverlays, 'shopinfo' + item.name)}
            doClose={() => {
                dispatch(closeOverlay({ name: 'all' }));
            }}
            buttonIcons={[
                {
                    icon: 'close',
                    intent: 'link',
                    onClick: (e) => {
                        e.preventDefault();
                        dispatch(closeOverlay({ name: 'all' }));
                    },
                },
            ]}
            zIndex={16000004}
            size="medium"
        >
            <Block>
                <p>
                    {item.phone && (
                        <React.Fragment>
                            {phoneLabel}: {item.phone}
                            <br />
                        </React.Fragment>
                    )}
                    {item.openingHours && (
                        <React.Fragment>
                            {openingHoursLabel}: {item.openingHours}
                            <br />
                        </React.Fragment>
                    )}
                    {item.email && (
                        <React.Fragment>
                            {emailLabel}: <a href={`mailto:${item.email}`}>{item.email}</a>
                            <br />
                        </React.Fragment>
                    )}
                    {item.address && (
                        <React.Fragment>
                            {addressLabel}: {item.address}
                        </React.Fragment>
                    )}
                </p>
            </Block>
        </Overlay>
    );
};

export default StoreInfoOverlay;
