import * as React from 'react';
import RenderGallery from 'ekaubamaja-ui/lib/Components/RenderGallery';
import Configurator, { ISelectedAttributes, ISwatchConfig } from 'components/Catalog/Product/Configurator';
import { useCallback, useEffect, useState } from 'react';
import { IPrice } from 'components/Catalog/Interfaces/IPrice';
import { IImage } from 'components/Catalog/Interfaces/IImage';
import ReactDOMServer from 'react-dom/server';
import Gallery from 'components/Catalog/Product/Gallery';
import { useMutation } from 'redux-query-react';
import { extractErrors } from '../../../helpers/request/extractErrors';
import formProductRequest, { IProductRequest } from 'data/requests/product/data/formProductRequest';
import toCartRequest from 'data/requests/product/toCartRequest';
import quoteIdRequest from 'data/requests/product/quoteIdRequest';
import FinanceCalculator from 'components/Financing/FinanceCalculator';
import { IFinanceResponse } from 'components/Financing/Interfaces/IFinance';
import Availability from 'components/Catalog/Product/Availability';
import { IAvailabilityResponse } from 'components/Catalog/Product/Interfaces/IAvailability';
import { empty } from '../../../helpers/empty';
import crossSaleRequest from 'data/requests/product/crossSaleRequest';
import CartButton from 'components/Catalog/Product/CartButton';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';
import Price from 'components/Catalog/Product/Price';

export enum ProductType {
    SIMPLE_PRODUCT_TYPE = 'simple',
}

export interface ISelectedProductInformation {
    isAvailable: boolean;
    productId?: string;
    images?: IImage[];
    prices?: IPrice;
    selectedAttributes?: ISelectedAttributes[];
}
export interface ICartButtonConfig {
    addToCartLabel: string;
    outOfStockLabel: string;
    isDisabled?: boolean;
}

export interface IProductConfig {
    id: string;
    name: string;
    type: string;
    price: IConfigPrice;
    sku: {
        label: string;
        value: string;
    };
    shortDescription?: string;
    isSalable: boolean;
    isSpecialPriceDiscount?: boolean;
    description?: string;
    priceFormat: string;
    successFormat: string;
    preTitle?: string;
    priceOld?: string;
    addToCartButton?: ICartButtonConfig;
    productNuggets?: string;
    notAvailableLabel?: string;
    eStorePriceLabel: string;
    storePriceLabel: string;
    eolHtml?: string;
}

export interface IConfigPrice {
    value: number;
    label: string;
    valueLabel: string;
}
interface IProps {
    dom: string;
    config: {
        swatchConfig: ISwatchConfig;
        productConfig: IProductConfig;
        financialConfig: IFinanceResponse;
        availabilityConfig?: {
            availabilityLabel: string;
            emailLabel: string;
            phoneLabel: string;
            openingHoursLabel: string;
            addressLabel: string;
            items: IAvailabilityResponse[];
        };
        smartdealConfig?: {
            smartdealLabel: string;
            feeLabel: string;
            smartdealUrl: string;
            monthlyFee: {
                label: string;
                value: string;
            };
        };
    };
}

const Detail = (props: IProps) => {
    const { dom, config } = props;
    const { productConfig, availabilityConfig } = config;
    const [selectedProductInformation, setSelectedProductInformation] = useState<ISelectedProductInformation>({
        isAvailable: true,
        productId: productConfig.id,
    });
    const [price, setPrice] = useState<IConfigPrice>(productConfig.price);
    const formatPrice = (price) => productConfig.priceFormat.replace('0,00', price.toFixed(2));
    let priceOld = productConfig.priceOld ? formatPrice(parseFloat(productConfig.priceOld)) : undefined;
    const [oldPrice, setOldPrice] = useState<string | undefined>(priceOld);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const parser = new DOMParser();
    const doc = parser.parseFromString(dom, 'text/html');
    const [gallery, setGallery] = useState<string | undefined>(
        doc.querySelector('[data-component="gallery"]')?.innerHTML,
    );
    const [{}, quoteRequest] = useMutation(() => quoteIdRequest());
    const [{}, checkCrossSaleRequest] = useMutation((quoteItemId) => crossSaleRequest(quoteItemId));
    const [{}, addToCartRequest] = useMutation((data, quoteIdMask: string) => toCartRequest(data, quoteIdMask));
    const addToCart = useCallback(async () => {
        setError(undefined);
        const quoteIdResponse = await quoteRequest();
        if (quoteIdResponse.status !== 200) {
            // Todo maybe a message?
            window.location.reload();
        }
        const { quoteId } = quoteIdResponse.body;

        const productRequestData: IProductRequest = formProductRequest(
            quoteId,
            productConfig.sku.value,
            productConfig.type,
            selectedProductInformation.selectedAttributes,
        );
        const response = await addToCartRequest(productRequestData, quoteId);
        if (response.status !== 200) {
            const errors = extractErrors(response);
            if (errors && errors.error) {
                setError(errors.error);
            }
        } else {
            setSuccess(productConfig.successFormat.replace('%1', productConfig.name));
            window.dispatchEvent(new CustomEvent('cart-altered'));
            const checkCrossSaleResponse = await checkCrossSaleRequest(response.body.item_id);

            if (checkCrossSaleResponse.body.url) {
                window.location.href = checkCrossSaleResponse.body.url;
            }
        }
    }, [addToCartRequest, quoteRequest]);
    useEffect(() => {
        if (selectedProductInformation) {
            if (price && selectedProductInformation.prices) {
                setPrice({
                    value: selectedProductInformation.prices.finalPrice.amount,
                    label: price.label,
                    valueLabel: formatPrice(selectedProductInformation.prices.finalPrice.amount),
                });
            }

            if (selectedProductInformation.prices?.oldPrice) {
                if (
                    selectedProductInformation.prices?.oldPrice.amount !==
                    selectedProductInformation.prices?.finalPrice.amount
                ) {
                    setOldPrice(formatPrice(selectedProductInformation.prices.oldPrice.amount));
                } else {
                    setOldPrice(undefined);
                }
            }

            if (selectedProductInformation.images) {
                const images = <Gallery images={selectedProductInformation.images} />;
                setGallery(ReactDOMServer.renderToString(images));
            }
        }
    }, [selectedProductInformation]);

    return (
        <React.Fragment>
            <div className="layout-product__column">
                <div className="layout-product__image">
                    <div className="product-image">{gallery && <RenderGallery dom={gallery} />}</div>
                    {productConfig.productNuggets && (
                        <div dangerouslySetInnerHTML={{ __html: productConfig.productNuggets }} />
                    )}
                </div>
            </div>
            <div className="layout-product__column">
                <div className={'layout-product__content'}>
                    <div className={'longtext'}>
                        {success && <div className={'cart-callout intent-success'}>{success}</div>}
                        {error && <div className={'cart-callout intent-danger'}>{error}</div>}
                        {productConfig.shortDescription && (
                            <React.Fragment>
                                <p dangerouslySetInnerHTML={{ __html: productConfig.shortDescription }} />
                                <hr />
                            </React.Fragment>
                        )}
                    </div>
                    {config.swatchConfig && (
                        <React.Fragment>
                            <div className={'product-configurator'}>
                                <Configurator
                                    config={config.swatchConfig}
                                    setSelectedProductInformation={setSelectedProductInformation}
                                />
                            </div>
                        </React.Fragment>
                    )}
                    {price && (
                        <React.Fragment>
                            <div className="product-pricing">
                                {selectedProductInformation.isAvailable && (
                                    <ul className="product-pricing__prices">
                                        <Price
                                            isSpecialPriceDiscount={productConfig.isSpecialPriceDiscount}
                                            price={price}
                                            oldPrice={priceOld}
                                            productId={selectedProductInformation?.productId}
                                            storePriceLabel={productConfig.storePriceLabel}
                                            eStorePriceLabel={productConfig.eStorePriceLabel}
                                        />
                                        {productConfig.addToCartButton && (
                                            <CartButton
                                                buttonConfig={productConfig.addToCartButton}
                                                addToCart={addToCart}
                                                isSalable={productConfig.isSalable}
                                            />
                                        )}
                                    </ul>
                                )}
                                {!selectedProductInformation.isAvailable && (
                                    <div className={'label notice'}>{productConfig.notAvailableLabel}</div>
                                )}
                            </div>
                            {config.financialConfig && (
                                <FinanceCalculator
                                    config={config.financialConfig}
                                    productId={selectedProductInformation.productId}
                                />
                            )}
                        </React.Fragment>
                    )}
                    {(productConfig.type === ProductType.SIMPLE_PRODUCT_TYPE ||
                        selectedProductInformation?.productId) &&
                        availabilityConfig && (
                            <Availability
                                items={availabilityConfig.items}
                                productId={selectedProductInformation?.productId}
                                availabilityLabel={availabilityConfig.availabilityLabel}
                                emailLabel={availabilityConfig.emailLabel}
                                phoneLabel={availabilityConfig.phoneLabel}
                                openingHoursLabel={availabilityConfig.openingHoursLabel}
                                addressLabel={availabilityConfig.addressLabel}
                            />
                        )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Detail;
