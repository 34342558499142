import * as React from 'react';
import { ActiveFilter, Assets, Filter, Item } from 'components/Catalog/Interfaces/List/IListResponse';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import FilterDrop from 'ekaubamaja-ui/lib/Applications/Automaailm/Components/FilterDrop';
import classNames from 'classnames';
import { useHistory } from 'react-router';

interface IProps {
    filters: Filter[];
    activeFilters: ActiveFilter[];
    assets: Assets;
    clearUrl: string;
}

const SelectFilters = (props: IProps) => {
    const { filters, activeFilters, assets, clearUrl } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const history = useHistory();

    return (
        <div className={'product-filters'}>
            <h3
                onClick={() => {
                    setMobileOpen(!mobileOpen);
                }}
            >
                <Icon kind="filter" width={16} height={16} className="icon" />
                <span>{assets.filterProductsLabel}</span>
                <Icon kind={mobileOpen ? 'arrow1-up' : 'arrow1-down'} width={12} height={12} className="arrow" />
            </h3>
            <ul className={classNames('product-filters__categories', { open: mobileOpen })}>
                {filters &&
                    filters.map((filter, key) => {
                        const htmlKey = `select-${filter.id}-${key}`;
                        if (!filter.items.length || filter.id === 'cat' || filter.id === 'price') {
                            return <React.Fragment key={key} />;
                        }

                        const options = filter.items.map((item: Item) => ({
                            id: item.value ? item.value : item.id,
                            label: item.label,
                            value: item.value ? item.value : item.id,
                            isActive: item.isActive,
                        }));
                        const selectedOptions = options.filter((item) => item.isActive);
                        return (
                            <li key={`wrapper-${filter.id}-${key}`}>
                                <FilterDrop
                                    key={htmlKey}
                                    label={filter.name}
                                    options={options}
                                    selectedOptions={selectedOptions}
                                    onChange={(event) => {
                                        const item = filter.items.find((item: Item) =>
                                            item.value
                                                ? item.value.toString() === event.target.value.toString()
                                                : item.id.toString() === event.target.value.toString(),
                                        );
                                        if (item && item.url) {
                                            history.push(item.url);
                                        }
                                    }}
                                />
                            </li>
                        );
                    })}
                {activeFilters.length > 0 &&
                    !(
                        activeFilters.length === 1 && activeFilters.find((filter: ActiveFilter) => filter.id === 'cat')
                    ) && (
                        <li className="reset">
                            <Button
                                title={assets.removeAllLabel}
                                size="small"
                                icon="close"
                                onClick={() => history.push(clearUrl)}
                            />
                        </li>
                    )}
            </ul>
        </div>
    );
};

export default SelectFilters;
