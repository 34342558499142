import React from 'react';
import Block from 'ekaubamaja-ui/lib/Components/Block';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay/index';
import { useSelector } from 'react-redux';
import { includes } from 'lodash';
import { ITranslations } from 'components/SearchBlock/SearchBlock';
import { IStoreState } from '../../../../../helpers/rootReducer';
import Img from 'components/Image/Img';
import TireTableRow from 'components/SearchBlock/TireSearch/LicencePlate/Overlay/TireTableRow';

interface IProps {
    hideResults: () => void;
    overlaySelector: string;
    tireResponse?: TireResponse;
    labels: ITranslations;
}

export interface TireResponse {
    title: string;
    tires: Array<Tire>;
}

export interface Tire {
    name: string;
    href: string;
    seasons: Array<Season>;
}

export interface Season {
    season: string;
    href: string;
    count: number;
}

const TireOverlay = (props: IProps) => {
    const { overlaySelector, tireResponse, hideResults, labels } = props;
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    return (
        <Overlay
            isOpen={includes(openOverlays, overlaySelector)}
            doClose={hideResults}
            layout="focusview"
            buttonIcons={[
                {
                    icon: 'close',
                    intent: 'link',
                    onClick: (e) => {
                        e.preventDefault();
                        hideResults();
                    },
                },
            ]}
            size="wide"
            title={tireResponse && Object.keys(tireResponse.tires).length + ' ' + labels.tireSizesFound}
        >
            {tireResponse && (
                <Block>
                    <p>
                        <b>{tireResponse.title}</b>
                    </p>
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>{labels.tireSizes}</th>
                                    <th className="center bold">
                                        <Img alt={labels.summerTires} iconName={'icon_summer'} />
                                    </th>
                                    <th className="center bold">
                                        <Img alt={labels.allWeatherTires} iconName={'icon_allweather'} />
                                    </th>
                                    <th className="center bold">
                                        <Img alt={labels.winterTires} iconName={'icon_winter'} />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {tireResponse.tires.map((tire: Tire) => {
                                    return <TireTableRow tire={tire} />;
                                })}
                            </tbody>
                        </table>
                    </div>
                </Block>
            )}
        </Overlay>
    );
};

export default TireOverlay;
