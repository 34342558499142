import * as React from 'react';
import { CSSProperties } from 'react';
import classNames from 'classnames';
import 'ekaubamaja-ui/lib/Components/CategorySelect/styles.scss';

export interface IControlInputProps {
    /** placeholder */
    placeholder?: string;
    /** Controlled value */
    value: string;
    /** Default value */
    defaultValue?: string;
    /** Disabled status */
    disabled?: boolean;
    /** Readonly status */
    readonly?: boolean;
    /** Focus handler */
    onFocus?: (event) => void;
    /** Blur handler */
    onBlur?: (event) => void;
    /** Change handler */
    onChange?: (event) => void;
    /** Keydown handler */
    onKeyDown?: (event) => void;
    /** Keyup handler */
    onKeyUp?: (event) => void;
    /** Keyup handler */
    onClick?: (event) => void;
    /** Password entry */
    secureTextEntry?: boolean;
    /** Focus field on mount */
    autoFocus?: boolean;
    /** Addon element to append to field */
    addOn?: React.ReactElement;
    /** Addon element to prepend to field */
    addOnFront?: React.ReactElement;
    /** Color higlight for errors */
    hasError?: boolean;
    /** Color and jiggle animation for errors */
    errorAnimated?: boolean;
    /** Autocomplete flag */
    autocomplete?: boolean;
    /** className to append */
    className?: string;
    /** Pass input ref */
    inputRef?: (ref) => void;
    /** Styles to apply to wrapper */
    style?: CSSProperties;
    /** Styles to apply to inner input field */
    inputStyle?: CSSProperties;
    /** Max character count */
    maxLength?: number;
    /** DOM target element name attribute */
    name?: string;
    /** Custom type */
    type?: 'text' | 'date' | 'time';
    /** DOM target element id attribute */
    id?: string;
    /** Value for aria-label if no visible label available */
    accessibleLabel?: string;
}

interface IState {
    hasFocus: boolean;
}

/**
 * Regular HTML input field with extended functionality and styling. Use this within form row.
 */
export default class ControlInputWithoutClass extends React.Component<IControlInputProps, IState> {
    static displayName: string = 'ControlInputWithoutClass';

    private didFocus: boolean = false;
    private innerRef;

    constructor(props: IControlInputProps) {
        super(props);
        this.state = {
            hasFocus: false,
        };
    }

    /**
     * Focuses inner input element
     * @public
     */
    focus = () => {
        if (this.innerRef) {
            this.innerRef.focus();
        }
    };

    /**
     * Blurs inner input element
     * @public
     */
    blur = () => {
        if (this.innerRef) {
            this.innerRef.focus();
            this.innerRef.blur();
        }
    };

    render() {
        return (
            <div
                className={classNames(
                    {
                        'has-error': this.props.hasError,
                        'error-animated': this.props.errorAnimated,
                        'has-addon': this.props.addOn,
                        'has-addon-front': this.props.addOnFront,
                        'has-value': this.props.value.length > 0,
                        'has-placeholder': this.props.placeholder,
                        focus: this.state.hasFocus,
                    },
                    this.props.className,
                )}
                style={this.props.style}
                onClick={(e) => {
                    e.preventDefault();
                    this.focus();
                }}
            >
                {this.props.addOnFront && <span className="control-input-addon-front">{this.props.addOnFront}</span>}
                <div className="input-box">
                    <input
                        style={this.props.inputStyle}
                        maxLength={this.props.maxLength}
                        className="control-input-input"
                        type={this.props.secureTextEntry ? 'password' : !!this.props.type ? this.props.type : 'text'}
                        value={this.props.value}
                        defaultValue={this.props.defaultValue}
                        disabled={this.props.disabled}
                        readOnly={this.props.readonly}
                        onFocus={(e) => {
                            this.setState({
                                hasFocus: true,
                            });
                            if (this.props.onFocus) {
                                this.props.onFocus(e);
                            }
                        }}
                        onBlur={(e) => {
                            this.setState({
                                hasFocus: false,
                            });
                            if (this.props.onBlur) {
                                this.props.onBlur(e);
                            }
                        }}
                        onChange={this.props.onChange}
                        onKeyDown={this.props.onKeyDown}
                        onKeyUp={this.props.onKeyUp}
                        onClick={this.props.onClick}
                        placeholder={this.props.placeholder}
                        // required={true}
                        // formNoValidate={true}
                        spellCheck={false}
                        ref={(e) => {
                            if (this.props.inputRef) {
                                this.props.inputRef(e);
                            }
                            if (e) {
                                this.innerRef = e;
                                if (this.props.autoFocus && !this.didFocus) {
                                    window.requestAnimationFrame(() => {
                                        e.focus();
                                    });
                                    this.didFocus = true;
                                }
                            }
                        }}
                        autoComplete={this.props.autocomplete ? 'on' : 'nope'} // Intentionally wrong to make it work in Chrome
                        name={this.props.name}
                        id={this.props.id}
                        {...(this.props.accessibleLabel && {
                            'aria-label': this.props.accessibleLabel,
                        })}
                    />
                </div>
                {this.props.addOn && <span className="control-input-addon">{this.props.addOn}</span>}
            </div>
        );
    }
}
