import React from 'react';
import Tooltip from 'ekaubamaja-ui/lib/Components/Tooltip';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import { ITranslations, IUrls } from 'components/SearchBlock/SearchBlock';
import { ITire, ITireOptions } from 'components/SearchBlock/TireSearch/TireSearchBlock';
import WidthSelect from 'components/SearchBlock/TireSearch/SelectFields/WidthSelect';
import HeightSelect from 'components/SearchBlock/TireSearch/SelectFields/HeightSelect';
import DiameterSelect from 'components/SearchBlock/TireSearch/SelectFields/DiameterSelect';
import SeasonSelect from 'components/SearchBlock/TireSearch/SelectFields/SeasonSelect';
import { stringify } from 'query-string';
import SearchButton from 'components/SearchBlock/SearchButton';

interface IProps {
    urls: IUrls;
    labels: ITranslations;
    selectedTire?: ITire;
    setSelectedTire: (tire?: ITire) => void;
    options: ITireOptions;
}

const TireSearchDataSelect = (props: IProps) => {
    const { urls, labels, options, selectedTire, setSelectedTire } = props;

    const constructTireListUrl = () => {
        let query = {};
        if (selectedTire?.width) {
            query = { ...query, laius: selectedTire.width };
        }

        if (selectedTire?.height) {
            query = { ...query, korgus: selectedTire.height };
        }

        if (selectedTire?.diameter) {
            query = { ...query, labimoot: selectedTire.diameter };
        }

        if (selectedTire?.season) {
            query = { ...query, rehvi_tuup: selectedTire.season };
        }

        return query ? `${urls.tireList}?${stringify(query)}` : urls.tireList;
    };

    return (
        <div className="box-search__group">
            <div className="box-search__label">
                <Tooltip
                    position="top"
                    interactionKind="hover"
                    content={
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: labels.tireTooltipWidth }} />
                            <div dangerouslySetInnerHTML={{ __html: labels.tireTooltipHeight }} />
                            <div dangerouslySetInnerHTML={{ __html: labels.tireTooltipDiameter }} />
                        </div>
                    }
                >
                    <span>{labels.findTiresByDimensions}</span>
                </Tooltip>
            </div>
            {options && (
                <div className="box-search__fields">
                    <WidthSelect
                        options={options.dimensions.width}
                        placeholder={labels.tireWidth}
                        selectedTire={selectedTire}
                        setSelectedTire={setSelectedTire}
                    />
                    <HeightSelect
                        options={options.dimensions.height}
                        placeholder={labels.tireHeight}
                        selectedTire={selectedTire}
                        setSelectedTire={setSelectedTire}
                    />
                    <DiameterSelect
                        options={options.dimensions.diameter}
                        placeholder={labels.rimDiameter}
                        selectedTire={selectedTire}
                        setSelectedTire={setSelectedTire}
                    />
                    <SeasonSelect
                        options={options.seasons}
                        placeholder={labels.season}
                        selectedTire={selectedTire}
                        setSelectedTire={setSelectedTire}
                    />
                    <SearchButton label={labels.find} href={constructTireListUrl()} />
                </div>
            )}
        </div>
    );
};

export default TireSearchDataSelect;
