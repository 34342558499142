import * as React from 'react';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface IProps {
    config: {
        title: string;
    };
}

export const Title = (props: IProps) => {
    const [pageTitle, setPageTitle] = useState(props.config.title);

    const effect = (e: CustomEvent) => {
        if (e.detail && e.detail.pageTitle) {
            setPageTitle(e.detail?.pageTitle);
        }
    };

    useEffect(() => {
        window.addEventListener('page-changed', (e: Event) => {
            effect(e as CustomEvent);
        });
    }, []);

    return <span>{pageTitle}</span>;
};
