import * as React from 'react';
import { CSSProperties } from 'react';
import classNames from 'classnames';

import TextareaAutosize from 'react-textarea-autosize';

import 'ekaubamaja-ui/lib/Components/CategorySelect/styles.scss';

export interface IControlTextareaProps {
    /** placeholder */
    placeholder?: string;
    /** Controlled value */
    value: string;
    /** Default value */
    defaultValue?: string;
    /** Disabled status */
    disabled?: boolean;
    /** Readonly status */
    readonly?: boolean;
    /** Focus handler */
    onFocus?: (event) => void;
    /** Blur handler */
    onBlur?: (event) => void;
    /** Change handler */
    onChange?: (event) => void;
    /** Keydown handler */
    onKeyDown?: (event) => void;
    /** Keyup handler */
    onKeyUp?: (event) => void;
    /** Keyup handler */
    onClick?: (event) => void;
    /** Focus field on mount */
    autoFocus?: boolean;
    /** Color higlight for errors */
    hasError?: boolean;
    /** className to append */
    className?: string;
    /** Pass input ref */
    inputRef?: (ref) => void;
    /** Styles to apply to wrapper */
    style?: CSSProperties;
    /** Styles to apply to inner input field */
    inputStyle?: Omit<CSSProperties, 'maxHeight' | 'minHeight'> & {
        height?: number;
    };
    /** Max rows count */
    maxRows?: number;
    /** Min rows count */
    minRows?: number;
    /** DOM target element name attribute */
    name?: string;
    /** DOM target element id attribute */
    id?: string;
    /** Value for aria-label if not visible label available */
    accessibleLabel?: string;
}

interface IState {
    hasFocus: boolean;
}

/**
 * Autogrowing textarea field with extended functionality and styling.
 */
export default class ControlTextAreaWithoutClass extends React.Component<IControlTextareaProps, IState> {
    static displayName: string = 'ControlTextareaWithoutClass';

    private didFocus: boolean = false;
    private innerRef;

    constructor(props: IControlTextareaProps) {
        super(props);
        this.state = {
            hasFocus: false,
        };
    }

    /**
     * Focuses inner input element
     * @public
     */
    focus = () => {
        if (this.innerRef) {
            this.innerRef.focus();
        }
    };

    /**
     * Blurs inner input element
     * @public
     */
    blur = () => {
        if (this.innerRef) {
            this.innerRef.focus();
            this.innerRef.blur();
        }
    };

    render() {
        return (
            <div
                className={classNames(
                    '',
                    {
                        'has-error': this.props.hasError,
                        'has-value': this.props.value.length > 0,
                        'has-placeholder': this.props.placeholder,
                        focus: this.state.hasFocus,
                        disabled: this.props.disabled,
                    },
                    this.props.className,
                )}
                style={this.props.style}
                onClick={(e) => {
                    e.preventDefault();
                    this.focus();
                }}
            >
                <div className="control-textarea-holder">
                    <TextareaAutosize
                        style={this.props.inputStyle}
                        className="control-textarea-textarea"
                        value={this.props.value}
                        defaultValue={this.props.defaultValue}
                        disabled={this.props.disabled}
                        readOnly={this.props.readonly}
                        onFocus={(e) => {
                            this.setState({
                                hasFocus: true,
                            });
                            if (this.props.onFocus) {
                                this.props.onFocus(e);
                            }
                        }}
                        onBlur={(e) => {
                            this.setState({
                                hasFocus: false,
                            });
                            if (this.props.onBlur) {
                                this.props.onBlur(e);
                            }
                        }}
                        onChange={this.props.onChange}
                        onKeyDown={this.props.onKeyDown}
                        onKeyUp={this.props.onKeyUp}
                        onClick={this.props.onClick}
                        placeholder={this.props.placeholder}
                        spellCheck={false}
                        ref={(node) => {
                            if (this.props.inputRef) {
                                this.props.inputRef(node);
                            }
                            if (node) {
                                this.innerRef = node;
                                if (this.props.autoFocus && !this.didFocus) {
                                    window.requestAnimationFrame(() => {
                                        node.focus();
                                    });
                                    this.didFocus = true;
                                }
                            }
                        }}
                        name={this.props.name}
                        id={this.props.id}
                        rows={1}
                        minRows={this.props.minRows}
                        maxRows={this.props.maxRows}
                        {...(this.props.accessibleLabel && {
                            'aria-label': this.props.accessibleLabel,
                        })}
                    />
                </div>
            </div>
        );
    }
}
