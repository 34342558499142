import FormRow from 'ekaubamaja-ui/lib/Components/FormRow/index';
import * as React from 'react';
import { IShippingMethod } from '../../../../interfaces/checkout/shipping/IShippingMethod';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { empty } from '../../../../helpers/empty';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm/index';
import ProceedButton from '../../../../components/checkout/ProceedButton';
import { useRef } from 'react';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput/index';
import { useMutation } from 'redux-query-react';
import { genericRequest } from '../../../../data/requests/genericRequest';
import { ResponseStatusEnum } from '../../../../enums/ResponseStatus';
import { dummyFields } from '../../../../components/address/dummyFields';
import { SHIPPING_METHOD_STEP } from '../../../../components/checkout/Content';
import { IAdditional, IAdditionalComponents } from '../../../../components/checkout/Shipping/Method/Additional';

interface IAdditionalLpPostOffice extends IAdditional {
    postoffice_name: string;
    postoffice_addr: string;
    shippingDescription: string;
}

interface IProps {
    selectedMethod?: IShippingMethod;
    proceedAction: () => void;
    setAdditionalData: (additionalData) => void;
    shippingAdditional: any | undefined;
    setShippingAdditional: (additional) => void;
    setCurrentStep: (step: number) => void;
    continueDisabled: boolean;
    additionalComponents: IAdditionalComponents;
}

interface IPostOfficeItem {
    name: string;
    address: string;
}

const LpPostOffice = (props: IProps) => {
    const {
        selectedMethod,
        proceedAction,
        setCurrentStep,
        setAdditionalData,
        shippingAdditional,
        setShippingAdditional,
        additionalComponents,
    } = props;
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState<IPostOfficeItem>();
    const [postCode, setPostCode] = useState('');

    const defaultSelected = useRef(false);
    const querying = useRef(false);

    const selectItem = (item) => {
        if (!defaultSelected.current) {
            defaultSelected.current = true;
        }

        setSelectedItem(item);
    };
    const url = selectedMethod?.extra_data?.ajax_url || '';
    const [{}, methodsRequest] = useMutation(() =>
        genericRequest({
            type: 'postOffice',
            url,
            data: { zipCode: postCode },
        }),
    );

    const resetState = () => {
        if (selectedItem) {
            setSelectedItem(undefined);
        }
    };

    const findPostOffice = () => {
        setCurrentStep(SHIPPING_METHOD_STEP);
        if (postCode && postCode !== dummyFields.postcode && postCode.length === 5) {
            if (!empty(url)) {
                querying.current = true;
                methodsRequest().then((response) => {
                    const result: Partial<IPostOfficeItem> = response.body;
                    if (response.status === ResponseStatusEnum.ok && empty(result.name)) {
                        resetState();
                    } else {
                        selectItem(result);
                    }
                });
            }
        } else {
            resetState();
        }
    };
    useEffect(() => {
        if (!querying.current) {
            findPostOffice();
        }
    });

    return (
        <React.Fragment>
            <div className="longtext">
                {selectedItem && selectedItem.address ? (
                    <p>
                        <b>{selectedItem.name}</b>
                        <br />
                        <span>{selectedItem.address}</span>
                    </p>
                ) : (
                    <p>{t('checkout.To find the closest post office please fill in the postcode')}</p>
                )}
            </div>
            <React.Fragment>
                <LayoutForm layout="vertical">
                    <FormRow label={t('checkout.Postcode')} required={true}>
                        <ControlInput
                            value={postCode}
                            onChange={(e) => {
                                if (e.target.value.length < 6) {
                                    setPostCode(e.target.value);
                                    querying.current = false;
                                }
                            }}
                        />
                    </FormRow>
                </LayoutForm>
                {additionalComponents.courierInfo && additionalComponents.courierInfo.component}
            </React.Fragment>
            <ProceedButton
                proceedAction={() => {
                    if (selectedItem) {
                        const additionalData: IAdditionalLpPostOffice | undefined = {
                            postoffice_name: selectedItem.name,
                            postoffice_addr: selectedItem.address,
                            shippingDescription: selectedItem.name,
                            courier_info: additionalComponents.courierInfo.exportField,
                        };
                        setAdditionalData(additionalData);
                        if (selectedMethod) {
                            shippingAdditional[selectedMethod.methodCode] = additionalData;
                            setShippingAdditional(shippingAdditional);
                        }
                        proceedAction();
                    }
                }}
                disabled={!selectedItem?.address || props.continueDisabled}
            />
        </React.Fragment>
    );
};
export default LpPostOffice;
