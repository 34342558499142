import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput/index';
import Icon from 'ekaubamaja-ui/lib/Components/Icon/index';
import React from 'react';
import Img from 'components/Image/Img';

interface IProps {
    licence: string;
    placeholder: string;
    iconName: string;
    label: string;
    setLicence: (licence: string) => void;
    onEnterEvent: () => void;
}

const LicencePlateInput = (props: IProps) => {
    const { licence, placeholder, setLicence, iconName, label, onEnterEvent } = props;

    return (
        <ControlInput
            addOnFront={<Img alt={label} iconName={iconName} />}
            addOn={
                licence !== '' ? (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            setLicence('');
                        }}
                    >
                        <Icon width={16} height={16} kind="clear" />
                    </button>
                ) : undefined
            }
            className="box-search__license"
            value={licence}
            placeholder={placeholder}
            onChange={(e) => {
                setLicence(e.target.value.toUpperCase());
            }}
            maxLength={9}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    onEnterEvent();
                }
            }}
        />
    );
};

export default LicencePlateInput;
