import * as React from 'react';

import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useOverlays from '../../../components/overlay/Overlay';

interface IProps {
    CartContent: any;
}

const CartOverlay = (props: IProps) => {
    const { CartContent } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const { overlays, closeOverlay } = useOverlays();

    const close = (e) => {
        e.preventDefault();
        history.goBack();
        closeOverlay('summary');
    };

    return (
        <Overlay
            isOpen={overlays.indexOf('summary') !== -1}
            layout="focusview"
            title={t('checkout.Order details')}
            size="small"
            icon="cart"
            doClose={(e) => close(e)}
            buttonIcons={[
                {
                    icon: 'close',
                    intent: 'link',
                    onClick: (e) => close(e),
                },
            ]}
        >
            {CartContent}
        </Overlay>
    );
};
export default CartOverlay;
