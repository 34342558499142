import React from 'react';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';

interface IProps {
    label: string;
    href: string;
    disabled?: boolean;
}

const SearchButton = (props: IProps) => {
    const { label, href, disabled } = props;

    return (
        <div className="column action">
            <Button
                disabled={disabled ? disabled : false}
                title={label}
                intent="primary"
                onClick={() => {
                    window.location.href = href;
                }}
            />
        </div>
    );
};

export default SearchButton;
