import * as React from 'react';
import ResultProduct from 'components/Catalog/Search/ResultProduct';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';
import { useKeypress } from 'ekaubamaja-ui/lib/Utilities/Generic';
import { useState } from 'react';
import ResultCategory from 'components/Catalog/Search/ResultCategory';
import Button from 'ekaubamaja-ui/lib/Components/Button';

export interface IProps {
    queryString: string;
    showAllResultsLabel: string;
    searchAction: () => void;
    // currentlyActive: number;
    // setTotalCount: (count: number) => void;
}

interface ResultResponse {
    name: string;
    url: string;
    type: string;
    price?: string;
    image?: {
        image_url: string;
    };
    parent?: string;
}

interface SearchResponse {
    results: ResultResponse[];
    totalCount: number;
}
const mapStateToProps = (state): SearchResponse => {
    return {
        results: state.entities.quickSearch,
        totalCount: state.entities.quickSearch?.length || 0,
    };
};
const Results = (props: IProps) => {
    const { queryString } = props;
    const [active, setActive] = useState(0);
    useRequest(
        request({ type: 'quickSearch', url: `search/ajax/suggest?q=${queryString}`, notApi: true, force: true }),
    );
    const { results, totalCount } = useSelector(mapStateToProps);

    useKeypress(
        'ArrowUp',
        () => {
            if (active > 0) {
                setActive(active - 1);
            } else {
                setActive(totalCount - 1);
            }
        },
        [totalCount, active],
    );

    useKeypress(
        'ArrowDown',
        () => {
            if (active < totalCount - 1) {
                setActive(active + 1);
            } else {
                setActive(0);
            }
        },
        [totalCount, active],
    );

    return (
        <ul className="search__results__list">
            {results &&
                results.map((result, i) => {
                    if (result.type === 'category') {
                        return (
                            <ResultCategory
                                key={result.name + i}
                                name={result.name}
                                href={result.url}
                                parent={result.parent}
                                active={active === i}
                            />
                        );
                    } else {
                        return (
                            <ResultProduct
                                key={result.name + i}
                                name={result.name}
                                imageUrl={result.image?.image_url}
                                href={result.url}
                                price={result.price}
                                active={active === i}
                            />
                        );
                    }
                })}
            {results && results.length > 0 && (
                <li className="search__results__actions">
                    <Button
                        title={props.showAllResultsLabel}
                        intent="primary"
                        type="anchor"
                        onClick={() => props.searchAction()}
                    />
                </li>
            )}
        </ul>
    );
};

export default Results;
