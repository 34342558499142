import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import 'ekaubamaja-ui/lib/Applications/Checkout/Components/Banklinks/styles.scss';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow/index';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel/index';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm/index';
import { useTranslation } from 'react-i18next';
import { ICustomer } from '../../../interfaces/checkout/customer/ICustomer';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';

interface ISubscriptionProps {
    customer: ICustomer | null;
    email: string | undefined;
}

const Subscription = (props: ISubscriptionProps) => {
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    const [showSubscription, setShowSubscription] = useState(false);
    const requestDone = useRef(false);
    const [email, setEmail] = useState(props.customer?.email ? props.customer?.email : props.email);

    const changeSubscription = () => {
        setIsChecked(!isChecked);
    };

    const [{}, checkSubscriptionRequest] = useMutation(() =>
        request({
            notApi: true,
            method: 'POST',
            type: 'newsletterCheck',
            url: 'newsletter/newsletter/check',
            data: {
                email,
            },
        }),
    );

    const [{}, subscribeRequest] = useMutation(() =>
        request({
            notApi: true,
            method: 'POST',
            type: 'newsletterSubscribe',
            url: 'newsletter/newsletter/subscribe',
            data: {
                email,
            },
        }),
    );

    const checkSubscription = useCallback(async () => {
        return await checkSubscriptionRequest();
    }, [checkSubscriptionRequest]);

    useEffect(() => {
        if (email && !requestDone.current) {
            checkSubscription().then((response) => {
                if (response.status === 200) {
                    setShowSubscription(!response.body.isSubscribed);
                }
                requestDone.current = true;
            });
        }
    }, [checkSubscriptionRequest, showSubscription]);

    const saveSubscription = () => {
        if (!showSubscription || !email || !isChecked) {
            return;
        }
        subscribeRequest();
    };

    useEffect(() => {
        window.addEventListener('subscription-save', saveSubscription);
        return function cleanup() {
            window.removeEventListener('subscription-save', saveSubscription);
        };
    });

    return showSubscription ? (
        <LayoutForm layout="vertical">
            <FormRow>
                <CheckboxWithLabel
                    label={t('checkout.subscribeToNewsletter')}
                    checked={isChecked}
                    onChange={() => changeSubscription()}
                />
            </FormRow>
        </LayoutForm>
    ) : (
        <React.Fragment />
    );
};
export default Subscription;
