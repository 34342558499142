import React from 'react';
import ControlSelect from 'ekaubamaja-ui/lib/Components/ControlSelect/index';
import { MatOptions } from 'components/SearchBlock/MatSearch/MatSearchSelect';

interface IProps {
    options: Array<MatOptions>;
    setAttribute: (attribute?: string) => void;
    attribute?: string;
    placeholder: string;
}

const MatDataSelect = (props: IProps) => {
    const { options, placeholder, attribute, setAttribute } = props;

    return (
        <div className="column">
            <div className="control-holder">
                <ControlSelect
                    value={attribute || ''}
                    onChange={(e) => setAttribute(e.target.value)}
                    placeholder={placeholder}
                >
                    <option value="" />
                    {options &&
                        options.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.label}
                            </option>
                        ))}
                    ;
                </ControlSelect>
            </div>
        </div>
    );
};

export default MatDataSelect;
